import React from "react";

export class InfoNumber extends React.Component {
  constructor(props) {
    super(props);
    this.countupRef = React.createRef();
    this.countUpAnim = null;
  }

  componentDidMount() {
    this.initCountUp();
  }

  async initCountUp() {
    const countUpModule = await import("countup.js");
    this.countUpAnim = new countUpModule.CountUp(
      this.countupRef.current,
      this.props.end || 1000,
      { enableScrollSpy: true }
    );
    if (!this.countUpAnim.error) {
      this.countUpAnim.start();
    } else {
      console.error(this.countUpAnim.error);
    }
  }

  render() {
    return (
      <h3 ref={this.countupRef} className={this.props.css}>
        {this.props.children}
      </h3>
    );
  }
}
