function isValidEmail(value) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailPattern.test(value);
}

function isStringMinLengthValid(value, min) {
  return value.length >= min;
}

function isValidPhone(value) {
  // digits and maybe a + sign at the beginning
  const validDigits = /^(\+)?\d+$/;

  return validDigits.test(value);
}
const ContactService = {
  validateContactFields: (formValues) => {
    const errors = {};
    const emailResult = isValidEmail(formValues.form_email);
    const nameResult = isStringMinLengthValid(formValues.form_name, 3);
    const phoneResult = isStringMinLengthValid(formValues.form_phone, 9);
    const validPhoneResult = isValidPhone(formValues.form_phone);
    const messageResult = isStringMinLengthValid(formValues.form_message, 10);

    if (
      emailResult &&
      nameResult &&
      phoneResult &&
      validPhoneResult &&
      messageResult
    ) {
      return { success: true, errors: {} };
    }

    if (!nameResult) {
      errors.form_name =
        "El nombre es requerido y debe tener al menos 3 caracteres";
    }
    if (!validPhoneResult) {
      errors.form_phone = "Ingrese un teléfono válido";
    }
    if (!phoneResult && !errors.form_phone) {
      errors.form_phone =
        "El teléfono es requerido y debe tener al menos 10 caracteres";
    }
    if (!emailResult) {
      errors.form_email = "Ingrese un correo válido";
    }

    if (!messageResult) {
      errors.form_message = "Escriba un mensaje con al menos 10 caracteres";
    }

    return {
      success: false,
      errors,
    };
  },
};

export default ContactService;
