import React from "react";

export class ServicesItemContainer extends React.Component {
  render() {
    return (
      <div
        style={{ textDecoration: "none", cursor: "pointer" }}
        className={`serviceItem col-md-3 p-md-2 mx-2 mb-lg-2 ${
          this.props.anima
        } ${this.props.extraClass || ""}`}
        // href="#services2"
      >
        {this.props.children}
      </div>
    );
  }
}
