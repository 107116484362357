import React, { Component } from "react";
import es from "../../assets/flags/mex.png";
import en from "../../assets/flags/eeuu.png";
import logo from "../../assets/img/Logo.png";
//import { Link } from "react-scroll";
import { Link as Link1 } from "react-router-dom";
import UsuarioService from "../../services/UsuarioService";
import JsHelper from "../../helpers/JsHelper";
import CssHelper from "../../helpers/CssHelper";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  static logout() {
    UsuarioService.Logout();
  }

  static translateText(language) {
    if (language === "en" || language === "es") {
      let pressedLang = JsHelper.getBySelector("#" + language);
      let idiomasDisponibles = JsHelper.getAllBySelector(".flagDiv");
      idiomasDisponibles.forEach((el) => CssHelper.removeClass(el, "coloriza"));
      CssHelper.addClass(pressedLang, "coloriza");
      this.changeLanguage(language);
      this.changeLanguage(language);
      if (language === "en") {
        let hidden = JsHelper.getAllBySelector(".txt-hide");
        hidden.forEach((el) => CssHelper.removeClass(el, "d-none"));
      } else {
        let hidden = JsHelper.getAllBySelector(".txt-hide");
        hidden.forEach((el) => CssHelper.addClass(el, "d-none"));
      }
    }
  }

  static changeLanguage(language) {
    let dropMenu = JsHelper.getByClass("goog-te-combo");
    let eventDispatcher = new Event("change");
    dropMenu.value = language;
    dropMenu.dispatchEvent(eventDispatcher);
  }

  static activeLink(page) {
    let pressedLang = JsHelper.getBySelector("#" + page);
    let idiomasDisponibles = JsHelper.getAllBySelector(".admin-link");
    idiomasDisponibles.forEach((el) =>
      CssHelper.removeClass(el, "nav-link-active")
    );
    CssHelper.addClass(pressedLang, "nav-link-active");
  }

  static FixNav() {
    const url = window.location.href;
    console.log(url);

    if (url.includes("blog")) {
      let serviceItem = JsHelper.getById("serviceItem");
      console.log(serviceItem);
      serviceItem.classList.add("d-none");
      let aboutItem = JsHelper.getById("aboutItem");
      aboutItem.classList.add("d-none");
    }
  }

  static renderServices() {
    return (
      <li className="nav-item">
        <a
          style={{ cursor: "pointer" }}
          className="nav-link"
          activeClass="active"
          href="#services"
        >
          Servicios
        </a>
      </li>
    );
  }

  static renderAbout() {
    return (
      <li className="nav-item" id="aboutItem">
        <a href="#about" className="nav-link">
          <span className="txt-hide d-none">Sobre </span>nosotros
        </a>
      </li>
    );
  }

  static renderScrollableHome() {
    return (
      <li className="nav-item active">
        <a href="#home" className="nav-link">
          <span className="txt-hide d-none">Página de </span>Inicio
        </a>
      </li>
    );
  }

  static renderLinkHome() {
    return (
      <li className="nav-item active">
        <a href="/" className="nav-link">
          <span className="txt-hide d-none">Página de </span>Inicio
        </a>
      </li>
    );
  }

  static renderBlog() {
    return (
      <li className="nav-item">
        <a href="#bottom" className="nav-link">
          Blog
        </a>
      </li>
    );
  }

  static renderNavAdmin() {
    //let usuario = UsuarioService.Get();
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
        id="adminNav"
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo maytech" className="my-2 logo-admin" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbarBorder navbar-nav text-uppercase mx-auto font-weight-bold">
              <li className="nav-item">
                <Link1 to="/" className="nav-link admin-link">
                  Maytech<span className="sr-only">(current)</span>
                </Link1>
              </li>
              <li className="nav-item">
                <Link1 className="nav-link admin-link" to="/admin/dashboard">
                  Página de Inicio
                </Link1>
              </li>
              <li className="nav-item">
                <Link1 className="nav-link admin-link" to="/admin/blog/list">
                  Panel de entradas
                </Link1>
              </li>
            </ul>

            <ul className="navbar-nav text-uppercase mt-2 ml-auto">
              <li>
                <button
                  className="btn btn-custom btn-blue-cta"
                  onClick={NavMenu.logout}
                >
                  Cerrar sesión
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  static renderNav(url) {
    let home = url.includes("blog")
      ? this.renderLinkHome()
      : this.renderScrollableHome();
    let services = url.includes("blog") ? null : this.renderServices();
    let about = url.includes("blog") ? null : this.renderAbout();
    let blog = url.includes("blog") ? null : this.renderBlog();
    return (
      <nav
        style={{ height: "75px" }}
        className="navbar bg-transparent animate-transparency navbar-dark fixed-top navbar-expand-xl paddingXaxis"
        id="mainNav"
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa fa-bars fa-2x text-white"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbarBorder navbar-nav text-uppercase mr-auto font-weight-bold">
            {home}
            {services}
            {about}
            {blog}
            <div className="navbarBottomBorder"></div>
          </ul>
          <ul className="navbar-nav text-uppercase mt-2 ml-auto">
            <li className="nav-item d-none d-md-block d-lg-block">
              <a
                href="#contact" // Usando href para que sea rastreable
                className="btn btn-light btn-contact bg-white text-black rounded-pill font-weight-bold px-3 mr-2"
                style={{ cursor: "pointer" }}
              >
                Contacto
              </a>
            </li>
            <li className="nav-item d-sm-flex d-md-none">
              <a
                href="#contact" // Usando href para que sea rastreable
                className="nav-link"
                activeClass="active"
                style={{ cursor: "pointer" }}
              >
                Contacto
              </a>
            </li>
            <li className="nav-item d-flex ml-lg-2">
              <div className="flagContainer d-flex align-items-start text-white font-weight-bold">
                <div
                  id="en"
                  className="d-flex flagDiv flag1"
                  onClick={() => this.translateText("en")}
                >
                  <img className="flag mr-2" src={en} alt="eeuu flag" />
                  <span className="mx-2">|</span>
                </div>

                <div
                  id="es"
                  className="d-flex flagDiv flag2 coloriza"
                  onClick={() => this.translateText("es")}
                >
                  <img className="flag ml-2" src={es} alt="mexico flag" />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div id="google_translate_element" style={{ display: "none" }}></div>
      </nav>
    );
  }

  render() {
    const url = window.location.href;
    let navbar =
      UsuarioService.Get() !== null
        ? NavMenu.renderNavAdmin()
        : NavMenu.renderNav(url);
    return <header>{navbar}</header>;
  }
}
