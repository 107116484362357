import React from "react";
import { ServicesItemContainer } from "./ServicesItemContainer";
import { ServicesTitle } from "./ServicesTitle";
import { ServicesDetails } from "./ServicesDetails";
import { Image } from "../../shared/Image";
import venta from "../../../assets/img/Venta.png";
import seguridad from "../../../assets/img/Seguridad.png";
import control from "../../../assets/img/Control.png";
import cctv from "../../../assets/img/CCTV.png";
import intrusion from "../../../assets/img/Intrusion.png";
import { ServicesDownItems } from "./ServicesDownItems";
import ICONOC_MAYTECH_12 from "../../../assets/img/ICONOC_MAYTECH-12.png";
import ICONOC_MAYTECH_13 from "../../../assets/img/ICONOC_MAYTECH-13.png";
import ICONOC_MAYTECH_14 from "../../../assets/img/ICONOC_MAYTECH-14.png";

export class ServicesItem extends React.Component {
  render() {
    return (
      <div className="row sectionPadding text-center d-flex justify-content-center text-uppercase">
        <ServicesItemContainer anima="anima_up2">
          <Image css="pt-2 pb-2" src={venta} alt="ventas" />
          <ServicesTitle>
            Venta de <br />
            equipo
          </ServicesTitle>
          <ServicesDetails>Equipo profesional para tu proyecto</ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up">
          <Image css="pt-2" src={seguridad} alt="seguridad" />
          <ServicesTitle>Seguridad integral</ServicesTitle>
          <ServicesDetails>
            Soluciones completas en sistemas de seguridad
          </ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up2">
          <Image css="pt-2 pb-2" src={control} alt="control de hogar" />
          <ServicesTitle>
            Control de <br />
            acceso
          </ServicesTitle>
          <ServicesDetails>
            Restringe el acceso con tecnología de gama
          </ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up">
          <Image css="pt-2 pb-3 mt-2" src={cctv} alt="cctv" />
          <ServicesTitle>Cctv</ServicesTitle>
          <ServicesDetails>
            Vigila tu casa o negocio desde el celular
          </ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up">
          <Image css="pt-2" src={intrusion} alt="intrusion" />
          <ServicesTitle>Intrusión</ServicesTitle>
          <ServicesDetails>Prevención automática de robo</ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up2" extraClass="d-md-none">
          <Image css="pt-2 pb-2" src={ICONOC_MAYTECH_13} alt="renovables" />
          <ServicesTitle>Energías Renovables</ServicesTitle>
          <ServicesDetails>
            Aprovecha los recursos naturales y ahorra electricidad
          </ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up" extraClass="d-md-none">
          <Image css="pt-2 pb-2" src={ICONOC_MAYTECH_12} alt="incendios" />
          <ServicesTitle>Sistema contra incendios</ServicesTitle>
          <ServicesDetails>
            Prevención automática de humo e incendios
          </ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up2" extraClass="d-md-none">
          <Image css="pt-2 pb-2" src={ICONOC_MAYTECH_14} alt="redes it" />
          <ServicesTitle>Redes IT y cableado estructurado</ServicesTitle>
          <ServicesDetails>
            Implementación de tecnología IT para tu negocio
          </ServicesDetails>
        </ServicesItemContainer>
        <ServicesDownItems />
      </div>
    );
  }
}
